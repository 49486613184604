<template>
  <div>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="content">
      <div class="sec-wrapper">
        <div class="icon-wrapper p-d-flex p-ai-center ml-4">
          <div>
            <img :src="require('@/assets/loan_summ_icon.svg')" alt />
            <p class="sec-title pm-txt-color">Loan Summary</p>
          </div>
        </div>
        <div class="section-1 ml-4" :class="isMD ? '' : 'flex'">
          <div class="grid-items-2 col">
            <div class="border-items">Loan type</div>
            <div class="wrap">
              <p class="text pm-txt-color">
                {{ selectedApplication.loanReferenceNumber }}
              </p>
            </div>
            <div class="wrap">
              <template v-if="isPreSettlement && selectedApplication.calculatedValue.interestFreeTermMonths > 0">
                <p class="heading lt-txt-color">Interest rate</p>
                <p class="text pm-txt-color">{{ interestRate * 100 }}% after interest free period</p>
              </template>
              <template v-else-if="isPreSettlement">
                <p class="heading lt-txt-color">Interest rate</p>
                <p class="text pm-txt-color">{{ interestRate * 100 }}%</p>
              </template>
            </div>
          </div>
          <div class="grid-items col">
            <div class="wrap">
              <div class="border-items">Loan details</div>
              <p class="heading lt-txt-color">Term</p>
              <p class="text pm-txt-color">{{ selectedApplication.calculatedValue.loanTermMonths }} months</p>
            </div>
            <div v-if="selectedApplication.calculatedValue.interestFreeTermMonths > 0" class="wrap">
              <div class="border-items border-content">
                <span style="visibility: hidden">Loan type</span>
              </div>
              <p class="heading lt-txt-color">Interest free period</p>
              <p class="text pm-txt-color">{{ selectedApplication.calculatedValue.interestFreeTermMonths }} months</p>
            </div>
            <div v-else class="wrap"></div>
            <div class="wrap">
              <p class="heading lt-txt-color">Set-up fee (%)</p>
              <p class="text pm-txt-color">
                {{ formatNumber(selectedApplication.calculatedValue.setupFeeRate * 100) }}%
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Set-up fee ($)</p>
              <p class="text pm-txt-color">{{ formatCurrency(selectedApplication.calculatedValue.setupFeeAmount) }}</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Broker fee (%)</p>
              <p class="text pm-txt-color">
                <span>{{ formatNumber(selectedApplication.calculatedValue.brokerFee * 100) }}% </span>
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Broker fee ($)</p>
              <p class="text pm-txt-color">
                {{ formatCurrency(brokerFeeAmount) }}
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Total set-up fee (%)</p>
              <p class="text pm-txt-color">{{ totalFeeRate }}</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Total set-up fee ($)</p>
              <p class="text pm-txt-color">
                <span>{{ formatCurrency(totalFeeAmount) }}</span>
              </p>
            </div>
            <div class="wrap"></div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan amount</p>
              <p class="text primary-color">
                {{ formatCurrency(selectedApplication.calculatedValue.initialAmountFinanced) }}
              </p>
            </div>
          </div>
        </div>
        <div class="section-2">
          <div class="table" :class="isMD ? '' : 'p-d-flex '">
            <div class="sec-content">
              <div class="title-icon p-d-flex p-ai-center">
                <img :src="require('@/assets/supporting_doc_color.svg')" alt />
                <p class="sec-title pm-txt-color">Supporting documents</p>
              </div>
              <DataTable :value="displaySupportingDocuments" responsiveLayout="scroll" paginator :rows="5">
                <Column field="name" header="Document">
                  <template #body="slotProps">
                    <div class="status-text">
                      {{ slotProps.data.name }}
                    </div>
                  </template>
                </Column>
                <Column field="status" header="Status">
                  <template #body="{ data }">
                    <div
                      class="status-text"
                      :class="[data.isReady ? 'color-highlight' : 'lt-txt-color']"
                      @click="data.isReady && openFileUploadModal(data)"
                    >
                      {{ data.statusDescription }}
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
            <div class="sec-content">
              <div class="title-icon p-d-flex p-ai-center">
                <img :src="require('@/assets/supporting_doc_color.svg')" alt />
                <p class="sec-title pm-txt-color">Special Conditions</p>
              </div>
              <DataTable :value="specialConditions" responsiveLayout="scroll" paginator :rows="5">
                <Column field="condition" header="Condition" />
                <Column field="statusDescription" header="Status" />
              </DataTable>
            </div>
          </div>
        </div>
        <PurchaseProperty :items="selectedApplication.incomingProperties" />
        <SaleProperty :items="selectedApplication.outgoingProperties" />
      </div>
      <div class="footer" v-if="isProgress">
        <Button
          class="mt-3"
          label="View loan progress"
          type="primary"
          iconPos="right"
          icon="pi pi-chevron-right"
          @click="toggleCmp"
        />
      </div>
    </div>
    <FileUpload
      :key="uploadKey"
      :display="displayFileuploadDialog"
      :taskId="selectedTask.id ?? ''"
      :taskTitle="selectedTask.title ?? ''"
      :status="selectedTask.status ?? ''"
      :externalDescription="selectedTask.externalDescription ?? ''"
      :documents="selectedTask.supportingDocuments ?? []"
      @completed="fileUploadCompleted"
      @skipped="closeBasic"
    />
  </div>
</template>

<script>
import { SPECIAL_CONDITION_STATUS, SUPPORTING_DOCUMENT_STATUS } from '@/constants';
import { formatCurrency, formatNumber, fromSnakeCase } from '@/services/formatHelper';
import { mapActions, mapGetters } from 'vuex';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import FileUpload from '../Progress/FileUpload.vue';
import PurchaseProperty from './PurchaseProperty.vue';
import SaleProperty from './SaleProperty.vue';
import Screensize from '@/mixins/screensize.mixin';

export default {
  components: {
    DataTable,
    Column,
    FileUpload,
    SaleProperty,
    PurchaseProperty,
  },
  mixins: [Screensize],
  props: ['cmp'],
  setup() {
    return {
      formatCurrency,
      formatNumber,
      fromSnakeCase,
    };
  },
  data() {
    return {
      uploadKey: 0,
      isLoading: true,
      isProgress: true,
      displayFileuploadDialog: false,
      selectedTask: {
        id: '',
        title: '',
        status: '',
        externalDescription: '',
        supportingDocuments: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedApplication: 'applications/selectedApplication',
      supportingDocuments: 'applications/supportingDocuments',
      tasksList: 'applications/tasksList',
    }),
    isPreSettlement() {
      return !['loan_funded', 'loan_repaid'].includes(this.selectedApplication.applicationStatus);
    },
    brokerFeeAmount() {
      return (
        (this.selectedApplication.calculatedValue.brokerFee ?? 0) *
        (this.selectedApplication.calculatedValue.loanAmount ?? 0)
      );
    },
    totalFeeAmount() {
      return this.selectedApplication.calculatedValue.setupFeeAmount + this.brokerFeeAmount;
    },
    totalFeeRate() {
      const rate =
        ((this.selectedApplication.calculatedValue.brokerFee ?? 0) +
          (this.selectedApplication.calculatedValue.setupFeeRate ?? 0)) *
        100;
      return formatNumber(rate) + '%';
    },
    displaySupportingDocuments() {
      return this.supportingDocuments?.map((data) => {
        const isReady = data.isInRange && data.status === 'not_yet_started';
        const statusDescription =
          SUPPORTING_DOCUMENT_STATUS[isReady ? data.status : 'not_yet_submitted'] ?? fromSnakeCase(data.status);
        return {
          taskId: data.id,
          name: fromSnakeCase(data?.taskTitle) || 'Supporting document',
          status: data.status,
          isReady,
          statusDescription,
          externalDescription: data.externalDescription,
          documents: data.documents,
        };
      });
    },
    specialConditions() {
      return this.selectedApplication.specialConditions.map((sc) => {
        return {
          condition: sc.task?.externalDescription ?? '-',
          status: sc.specialConditionStatus,
          statusDescription:
            SPECIAL_CONDITION_STATUS[sc.specialConditionStatus] ?? fromSnakeCase(sc.specialConditionStatus),
        };
      });
    },
    interestRate() {
      const introRate = this.selectedApplication.assessmentRates.find(
        (rate) => rate.assessmentRateType === 'introductory_variable_interest_rate',
      );
      if (introRate) {
        return introRate?.assessmentRate;
      } else {
        const bridgingRate = this.selectedApplication.assessmentRates.find(
          (rate) => rate.assessmentRateType === 'bridging_variable_interest_rate',
        );
        return bridgingRate?.assessmentRate;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchLoanSummary: 'applications/fetchLoanSummary',
      fetchLoanProgress: 'applications/fetchLoanProgress',
      fetchSupportingDocuments: 'applications/fetchSupportingDocuments',
    }),
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    toggleCmp() {
      this.$emit('toggleCmp');
    },
    openBasic() {
      this.displayFileuploadDialog = true;
    },
    closeBasic() {
      this.displayFileuploadDialog = false;
    },
    openFileUploadModal(data) {
      this.uploadKey += 1;
      Object.assign(this.selectedTask, {
        id: data.taskId,
        title: data.name,
        status: data.status,
        externalDescription: data.externalDescription,
        supportingDocuments: data.documents,
      });
      this.displayFileuploadDialog = true;
    },
    async fileUploadCompleted() {
      this.displayFileuploadDialog = false;
      await this.fetchLoanSummary();
    },
    formatPurpose(purpose) {
      const obj = {
        buy_now: 'Buy now',
        renovate: 'Renovate',
        cash_advance: 'Cash advance',
      };
      return obj[purpose] ? obj[purpose] : purpose;
    },
  },
  async created() {
    this.isProgress = this.$route.query.isProgress == true ? true : false;
    this.isLoading = true;
    try {
      await Promise.all([this.fetchLoanSummary(), this.fetchLoanProgress(), this.fetchSupportingDocuments()]);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

p,
:deep(td) {
  font-family: Gordita-medium !important;
}
:deep(.p-panel .p-panel-header .p-panel-header-icon) {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  display: none !important;
}
.content {
  margin-top: 2rem;
  font-family: HK-medium;
}

.sec-wrapper {
  border-bottom: 1px solid $light-text-color;
  padding-bottom: 1rem;
  font-family: $font-family !important;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 1rem;
}

.title {
  font-weight: bold;
  color: $black-color;
  font-size: 2.1rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 2;
}

.text {
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.field-not {
  width: 100%;
}

.sec-title {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 600;
}

.second-row {
  margin-top: 1rem;
}

.heading,
.text {
  font-size: 0.875rem !important;
}

.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.section-1 {
  margin-top: 2rem;
  border-bottom: 1px solid $light-text-color;
  padding-bottom: 1rem;

  .details-content {
    gap: 1.375rem;
  }

  .grid-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0 1rem;
    width: 90%;
  }
  .grid-items-2 {
    padding: 0 1rem;
    width: 90%;
  }
}

.section-2 {
  margin-top: 1.875rem;

  .table {
    width: 100%;
  }

  .title-icon {
    padding: 0 1rem;
    gap: 1rem;
    align-items: center;
  }

  .sec-content {
    padding: 0 1rem;
    margin-top: 1rem;
    width: 100%;
  }

  :deep(.p-datatable .p-datatable-thead > tr > th) {
    color: $light-text-color;
    background-color: #fff;
    font-size: 1rem;
    font-weight: bold !important;
  }

  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    text-align: left;
    border-bottom: 1px solid $light-text-color;
    color: $primary-text-color;
    font-size: 0.875rem;
    font-weight: bold !important;
  }
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  font-weight: bold !important;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  font-weight: bold !important;
}
.section-3 {
  margin-top: 1.875rem;
  width: 100%;

  .title-icon {
    gap: 1rem;

    .title {
      font-size: 1.25rem;
      margin: 0;
    }
  }
  .sec-row {
    gap: 1rem;
    justify-content: space-between;

    .address-text {
      margin: 0;
      width: 50%;
    }

    .col {
      gap: 2rem;
      width: 50%;

      p {
        margin: 0;
        gap: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.icon-wrapper {
  gap: 1rem;
  padding: 0 1rem;

  .icon {
    font-size: 2rem;
  }
}

.heading {
  margin: 0;
  font-weight: bold;
  font-size: 1.25rem;
}
:deep(.p-panel .p-panel-content) {
  font-weight: bold !important;
}
.primary-color {
  color: $primary-color;
}
.border-items {
  font-size: 0.875rem !important;
  margin: 0;
  font-weight: bold;
  color: #0200384d !important;
  border-bottom: 1px solid #0200384d !important;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem !important;
}
.border-content {
  margin-left: -0.5rem !important;
}
.color-highlight {
  color: $primary-color !important;
  // text-decoration: underline !important;
  cursor: pointer;
}
.back {
  color: rgba(2, 0, 56, 0.4);
}
.status-text {
  text-align: left;
  color: $primary-text-color;
  font-size: 0.875rem;
  text-decoration: none;
}
</style>
